import React from 'react';
import { Statistic } from 'antd';
import styled from 'styled-components';

import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';

// img
import headerBackground from '../images/whoweare/header.jpg';
import WhoWeAreImage from '../images/whoweare/Header-WhoWeAre-2.jpg';

// style-component
const WhoweareBelieve = styled.article`
  padding-top: 45px;
  padding-bottom: 45px;
`;

const WhoweareTextTitle = styled.h1`
  font-size: 2em;
  line-height: 40px;
  color: #333333;
  font-weight: 100;

  @media only screen and (max-width: 425px) {
    font-size: 1.5rem;
  }
`;

const DContainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;
`;

const WhoweareText = styled.p`
  margin-bottom: 20px;
  font-size: 1rem;
  color: #666666;
  font-weight: 100;

  @media only screen and (max-width: 425px) {
    font-size: 0.7rem;
  }
`;

const WhoweareTextgroup = styled.article`
  height: auto;
  width: 100%;
  background-image: url(${WhoWeAreImage});
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position-x: 50%;
  background-position-y: 50%;
`;

const WhoweareTextgroupEffect = styled.div`
  height: 100%;
  width: auto;
  background: #ffffff;
  opacity: 0.8;
  padding-top: 45px;
`;

const WhoweareSeparator = styled.div`
  width: 100%;
  margin-top: 3px;
  border-top-width: 2px;
  border-color: #ffd700;
  border-top-style: solid;
`;

const WhoweareStats = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 45px 0;

  @media only screen and (max-width: 425px) {
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
  }
`;

const WhoWeAre = React.memo(() => (
  <Layout>
    <Seo title="Quienes Somos" />
    <MiniHeader text="Quienes Somos" background={headerBackground} />
    <WhoweareBelieve>
      <DContainer>
        <WhoweareTextTitle>Creemos en "Construir a conciencia".</WhoweareTextTitle>
        <WhoweareText>
          Eso significa garantizar que todos los productos de construcción no solo sean seguros,
          efectivos y fáciles de instalar, sino también ambientalmente responsables y sostenibles.
          Sabemos que siempre estaras buscando la tecnología innovadora para crear edificios
          energéticamente eficientes con una estética superior.
        </WhoweareText>
        <WhoweareText>
          Eso es exactamente lo que nuestros productos te ayudan a lograr. Los productos como
          nuestros sistemas de muros, revestimientos y acabados son los favoritos entre los
          profesionales del diseño, los contratistas y los propietarios. Sean cuales sean sus
          necesidades o visión, ofrecemos productos para cada tipo de proyecto de construcción; ya
          sea nueva construcción, restauración o panelización, trabajo comercial o residencial.
        </WhoweareText>
        <WhoweareText>
          Como arquitecto o especificador, te centras en la estética y la viabilidad. Como
          contratista, desea productos con los que sea fácil trabajar. Como propietario de un
          inmueble, desea mantener el valor de su propiedad alto y con bajos costos de
          mantenimiento. Cualquiera que sea su función, Sto comprende sus necesidades únicas y
          ofrece materiales e soluciones inteligentes e innovadores que facilitan su trabajo. Eso es
          lo que nos convierte en un líder innovador en sistemas integrados de muros exteriores.
          Pero eso no para ahí.....
        </WhoweareText>
        <WhoweareText>
          Cuando combina ese compromiso con el soporte del producto y la innovación con ofertas de
          valor agregado como diseño consultivo y servicios de color a través de Sto Studio o
          capacitación en técnicas de aplicación adecuadas a través del Instituto Sto, obtiene una
          solución integrada de sistema de pared exterior sin igual en la industria.
        </WhoweareText>
      </DContainer>
    </WhoweareBelieve>
    <WhoweareTextgroup>
      <WhoweareTextgroupEffect>
        <DContainer>
          <Fade left>
            <WhoweareTextTitle>
              El Grupo Sto <br /> PRESENCIA GLOBAL • CONOCIMIENTO LOCAL
            </WhoweareTextTitle>
          </Fade>
          <Slide left>
            <WhoweareSeparator />
          </Slide>
          <Fade right>
            <div style={{ padding: '45px 0' }}>
              <WhoweareText>
                Desde que introdujo el sistema de aislamiento y acabado más eficiente en energía y
                fácil de aplicar en el mundo en 1963, Sto se ha dedicado a establecer constantemente
                nuevos puntos de referencia de la industria mundial en torno a las necesidades de
                cada cliente.
              </WhoweareText>
              <WhoweareText>
                Sto se compone de numerosas filiales, sucursales, distribuidores, plantas, almacenes
                e innumerables proyectos exitosos en todo el mundo. Esta red de largo alcance
                facilita el progreso en tecnología, brinda accesibilidad a los clientes en todo el
                mundo y crea una base sólida para el crecimiento futuro. Somos la única empresa de
                fabricación en la industria de la construcción con certificación ISO en los Estados
                Unidos, Alemania y China.
              </WhoweareText>
            </div>
          </Fade>
          <Slide right>
            <WhoweareSeparator />
          </Slide>
          <Fade>
            <Flash>
              <WhoweareStats>
                <Statistic title="PAÍSES OPERANDO" value={87} />
                <Statistic title="PERSONAL GLOBAL" value={5032} />
                <Statistic title="$ VENTAS GLOBALES" value={1300000000} suffix="+" />
              </WhoweareStats>
            </Flash>
          </Fade>
        </DContainer>
      </WhoweareTextgroupEffect>
    </WhoweareTextgroup>
  </Layout>
));

export default WhoWeAre;
